import React, { useState } from 'react';
import './LandingPage.css';

const LandingPage = () => {
  const [email, setEmail] = useState('');

  const handleJoinWaitlist = async () => {
    if (!email) {
      alert('Please enter a valid email address.');
      return;
    }

    const waitlistData = {
      waitlist_id: 22639,
      referral_link: 'https://getwaitlist.com/waitlist/22783',
      heartbeat_uuid: '',
      widget_type: 'WIDGET_1',
      email: email,
      answers: [],
    };

    try {
      const response = await fetch('https://api.getwaitlist.com/api/v1/waiter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(waitlistData),
      });

      if (response.ok) {
        alert('Successfully joined the waitlist!');
        setEmail('');
      } else {
        alert('There was an error joining the waitlist. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error. Please check your internet connection.');
    }
  };

  return (
    <div className="landing-page">
      <h1>Transforming Energy Delivery with Wireless Power Beaming</h1>
      <p>Flux is building a future where drones and electric aircraft are powered wirelessly, enabling uninterrupted operations.</p>
      <div className="input-group">
        <input
          id="emailInput"
          type="email"
          placeholder="Your Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={handleJoinWaitlist}>Get Notified</button>
      </div>
    </div>
  );
};

export default LandingPage;